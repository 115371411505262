import * as React from 'react';
import classnames from 'classnames';
import {PreviewJobMatchData, Transcript} from 'types';
import classes from './TranscriptView.module.css';
import {useBranding} from 'context/Branding';
import Jobs from 'components/Jobs/Jobs';
import {TypingAnimation} from 'components/Thread/Thread';
import ChatbotDefaultAvatar from 'components/ChatbotDefaultAvatar/ChatbotDefaultAvatar';
import {ReactComponent as DefaultAvatar} from 'images/silhouette.svg';
import {HandlePostMessage} from 'types';

const TranscriptView = ({
  transcript,
  onSubmit,
  qnOverSms = false,
  jobSelected = false,
  messagesLength,
}: {
  transcript: Transcript;
  onSubmit: HandlePostMessage;
  qnOverSms?: boolean;
  jobSelected?: boolean;
  messagesLength: number;
}) => {
  const [showMatchDetails, setShowMatchDetails] = React.useState(
    qnOverSms && !jobSelected ? true : false,
  );
  const [showJobDetails, setShowJobDetails] = React.useState(false);
  const [
    selectedMatch,
    setSelectedMatch,
  ] = React.useState<PreviewJobMatchData | null>(null);
  const brandingSetting = useBranding();
  React.useEffect(() => {
    if (qnOverSms && !jobSelected) {
      const searchParams = new URL(window.location.href).searchParams;
      const s_jid = searchParams.get('s_jid');

      for (const message of transcript.data) {
        if (message.job_matches) {
          for (const match of message.job_matches) {
            if (match.external_job_id === s_jid) {
              setSelectedMatch(match);
              return;
            }
          }
        }
      }
    }
    setSelectedMatch(null);
  }, [qnOverSms, jobSelected, transcript.data]);

  const {error, loading, data} = transcript;

  return (
    <div className={classes.root}>
      {loading && (
        <div className={classes.bubbleCenter}>
          <TypingAnimation />
        </div>
      )}
      {error && (
        <div className={classes.bubbleError}>
          <p>Sorry, there was an error loading your conversation history</p>
        </div>
      )}
      {data.length !== 0 && (
        <>
          {data.map(({text, user_type, is_autofilled, job_matches}, index) => {
            const isIncoming = user_type === 'bot';

            return (
              <div
                className={classnames(
                  classes.container,
                  isIncoming
                    ? classes.containerIncoming
                    : classes.containerOutgoing,
                )}
                tabIndex={0}
                key={index}
              >
                <div
                  className={classnames(
                    classes.namedAvatar,
                    !isIncoming && classes.namedAvatarOutgoing,
                  )}
                >
                  <div
                    className={classnames(
                      classes.avatar,
                      !isIncoming && classes.avatarOutgoing,
                    )}
                  >
                    {isIncoming ? (
                      !brandingSetting.chatbot_avatar ? (
                        <img
                          className={classes.favicon}
                          src={brandingSetting.chatbot_avatar}
                          alt="Chatbot avatar"
                        />
                      ) : (
                        <ChatbotDefaultAvatar
                          className={classes.favicon}
                          color={brandingSetting.color}
                        />
                      )
                    ) : (
                      <DefaultAvatar />
                    )}
                  </div>
                </div>
                {job_matches !== undefined ? (
                  <>
                    <div className={classes.jobMatchRoot}>
                      <div className={classes.titleRow}>
                        <div>
                          {`${job_matches.length} ${
                            job_matches.length > 1 ? 'matches' : 'match'
                          } found`}
                        </div>
                        {job_matches.length !== 0 && (
                          <div
                            className={classes.linkText}
                            onClick={() => setShowMatchDetails(true)}
                          >
                            See all
                          </div>
                        )}
                      </div>
                      {job_matches.map(job => {
                        const {title, location, sense_url, html} = job;

                        return (
                          <div
                            className={classes.jobRow}
                            key={title + location}
                            onClickCapture={event => {
                              if (qnOverSms) {
                                event.preventDefault();
                                event.stopPropagation();
                                setShowMatchDetails(true);
                                setShowJobDetails(true);
                                setSelectedMatch(job);
                              }
                            }}
                          >
                            {html.length > 0 ? (
                              <div
                                className={classes.customJobResultWithLink}
                                dangerouslySetInnerHTML={{
                                  __html: html,
                                }}
                                style={{
                                  display: 'unset',
                                }}
                              />
                            ) : (
                              <>
                                <a
                                  href={sense_url}
                                  className={classes.linkText}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <>{title}</>
                                </a>
                                <div>{location}</div>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>

                    {showMatchDetails && (
                      <Jobs
                        matches={job_matches}
                        setShowMatchDetails={setShowMatchDetails}
                        onSubmit={onSubmit}
                        isLast={!jobSelected && messagesLength === 0}
                        isQuestionNode={qnOverSms}
                        selectedMatch={selectedMatch}
                        showJobDetails={showJobDetails}
                        setShowJobDetails={setShowJobDetails}
                      />
                    )}
                  </>
                ) : (
                  <p
                    className={classnames(
                      isIncoming
                        ? classes.bubbleIncoming
                        : classes.bubbleOutgoing,
                      is_autofilled && classes.isAutofilled,
                    )}
                  >
                    {text}
                  </p>
                )}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default TranscriptView;
