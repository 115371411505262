import * as React from 'react';

import {
  ApiMessage,
  TextData,
  DatePickerAccessoryData,
  ChatbotDispatch,
  JobMatchMessage,
  MultiSelectMessage,
  SingleSelectMessage,
  SummaryStatusMessage,
  DatePickerMessage,
  FileUploadMessage,
  LATDisconnect,
  HandlePostMessage,
  JobResultListStyle,
  AccessoryJobData,
} from 'types';

import formatISO from 'date-fns/formatISO';

import {senseApi} from 'utils/bot-api';
import {isJobMatchQuestionNode} from 'utils/job';

import {useTransformedStateApi, useStateResourceApi} from 'hooks/useApi';
import {TextInputContext} from 'context/TextInputContext';
import {useChatbotState} from 'context/ChatbotState';
import postMultiSelectMessage from 'utils/postMultiSelectMessage';

// @ts-ignore
import Button, {UnstyledButton} from '../../@spaced-out/components/button';
import {ButtonGroup, ButtonStyle} from 'components/ButtonGroup/ButtonGroup';
import {FeedbackAccessory} from 'components/Feedback/Feedback';
import {TypingAnimation} from 'components/Thread/Thread';
import Jobs from 'components/Jobs/Jobs';

import {FullWeekPicker} from '@spaced-out/ui-lib/lib/calendar/FullPicker';

import css from './Accessories.module.css';

export function Accessory({
  onSubmit,
  accessory,
  buttonStyle,
  handleScrollToMessage,
  dispatch,
  isLast,
}: {
  accessory: ApiMessage;
  onSubmit: HandlePostMessage;
  buttonStyle: ButtonStyle;
  handleScrollToMessage: () => any;
  dispatch: ChatbotDispatch;
  isLast?: boolean;
}) {
  switch (accessory.type) {
    /*
    case 'datepicker':
      return <DatePickerAccessory accessory={accessory} onSubmit={onSubmit} />;
    case 'companypicker':
      return (
        <DataSelectAccessory
          text={accessory.text}
          onSubmit={onSubmit}
          dataSource="/companies.csv"
        />
      );
    case 'jobtitlepicker':
      return (
        <DataSelectAccessory
          text={accessory.text}
          onSubmit={onSubmit}
          dataSource="/jobtitles"
        />
      );
     */
    case 'feedback':
      return <FeedbackAccessory onSubmit={onSubmit} />;
    case 'multi-select':
      return (
        <MultiSelectAccessory
          accessory={accessory}
          buttonStyle={buttonStyle}
          onSubmit={onSubmit}
        />
      );
    case 'single-select':
      return (
        <ButtonAccessory
          accessory={accessory}
          buttonStyle={buttonStyle}
          onSubmit={onSubmit}
        />
      );
    case 'scheduler':
      return (
        <div className={css.meetingNode}>
          <SchedulerAccessory
            onSubmit={onSubmit}
            templateId={accessory.template_id}
            handleScrollToMessage={handleScrollToMessage}
          />
        </div>
      );
    case 'summary':
      return (
        <ButtonAccessory
          accessory={accessory}
          onSubmit={onSubmit}
          buttonStyle={buttonStyle}
        />
      );
    case 'job_match':
      return (
        <JobMatchAccessory
          accessory={accessory}
          onSubmit={onSubmit}
          buttonStyle={buttonStyle}
          dispatch={dispatch}
          isLast={isLast}
        />
      );
    case 'file_upload':
      return (
        <FileUploadAccessory
          accessory={accessory}
          onSubmit={onSubmit}
          buttonStyle={buttonStyle}
        />
      );
    case 'lat-wait-start':
      return (
        <LATWaitStartAccessory
          connectionWaitMessage={accessory.text}
          onCancel={onSubmit}
          dispatch={dispatch}
          isLast={isLast ?? true}
        />
      );
    case 'lat-agent-drop':
      return (
        <LATAgentDropAccessory
          accessory={accessory}
          dispatch={dispatch}
          isLast={isLast ?? true}
        />
      );
  }

  return null;
}

function LATWaitStartAccessory({
  connectionWaitMessage,
  onCancel,
  dispatch,
  isLast,
}: {
  connectionWaitMessage: string | undefined;
  onCancel: HandlePostMessage;
  dispatch: ChatbotDispatch;
  isLast: boolean;
}) {
  const {state} = useChatbotState();
  return (
    <div className={css.waitStartContainer}>
      <p className={css.waitStartMessage}>
        {connectionWaitMessage ??
          'Connecting you with a recruiter... please standby'}
      </p>
      {(isLast || state?.mode === 'lat_wait') && (
        <UnstyledButton
          className={css.smallTertiaryButton}
          onClick={() => {
            dispatch({type: 'set_chatbot_mode', payload: 'lat_canceled'});
            onCancel('cancel', 'WAIT_CANCEL::');
          }}
        >
          <div className={css.waitCancelButtonText}>Cancel Request</div>
        </UnstyledButton>
      )}
    </div>
  );
}

function LATAgentDropAccessory({
  accessory,

  dispatch,
  isLast,
}: {
  accessory: LATDisconnect;
  dispatch: ChatbotDispatch;
  isLast: boolean;
}) {
  return (
    <div className={css.agentDropContainer}>
      <p className={css.agentDropMessage}>
        {/*@ts-ignore prop doesn't exist yet*/}
        {accessory?.text ?? 'The conversation has been left'}
      </p>
    </div>
  );
}

function List({
  listStyle,
  children,
}: {
  listStyle: JobResultListStyle;
  children: React.ReactNode;
}) {
  switch (listStyle) {
    case 'Bulleted List':
      return <ul className={css.jobRow}>{children}</ul>;
    case 'Numbered List':
      return <ol className={css.cojobRowlumn}>{children}</ol>;
    default:
      return <>{children}</>;
  }
}

function ListItem({
  listStyle,
  children,
}: {
  listStyle: JobResultListStyle;
  children: React.ReactNode;
}) {
  if (['Bulleted List', 'Numbered List'].includes(listStyle)) {
    return <li className={css.listItem}>{children}</li>;
  } else {
    return <>{children}</>;
  }
}

function JobMatchAccessory({
  isLast,
  accessory,
  buttonStyle,
  onSubmit,
  dispatch,
}: {
  isLast?: boolean;
  accessory: JobMatchMessage;
  buttonStyle: ButtonStyle;
  onSubmit: HandlePostMessage;
  dispatch: ChatbotDispatch;
}) {
  const [showMatchDetails, setShowMatchDetails] = React.useState(false);
  const [
    selectedMatch,
    setSelectedMatch,
  ] = React.useState<AccessoryJobData | null>(null);
  const [showJobDetails, setShowJobDetails] = React.useState(false);
  const jobsToShow = accessory.payload.slice(
    0,
    Math.min(accessory.matches_count, accessory.max_matches),
  );

  const listStyle =
    jobsToShow[0].html.length > 0
      ? accessory.result_display_style
      : 'No Styling';

  const isQuestionNode = isJobMatchQuestionNode(accessory);
  const matchCount = accessory.payload.length;

  return (
    <>
      <div className={css.jobMatchRoot}>
        {accessory.show_all_jobs_button && (
          <div className={css.titleRow}>
            <div>
              {`${matchCount} ${matchCount > 1 ? 'matches' : 'match'} found`}
            </div>
            {matchCount !== 0 && (
              <div
                className={css.linkText}
                onClick={() => setShowMatchDetails(true)}
              >
                See all
              </div>
            )}
          </div>
        )}
        {jobsToShow && (
          <List listStyle={listStyle}>
            {jobsToShow.map(job => {
              const {title, location, sense_url, html} = job;

              return (
                <div
                  key={title + location}
                  className={css.jobRow}
                  onClickCapture={event => {
                    if (isQuestionNode) {
                      event.preventDefault();
                      event.stopPropagation();
                      setShowMatchDetails(true);
                      setShowJobDetails(true);
                      setSelectedMatch(job);
                    }
                  }}
                >
                  {html.length > 0 ? (
                    <ListItem listStyle={listStyle}>
                      <div
                        className={css.customJobResultWithLink}
                        dangerouslySetInnerHTML={{
                          __html: html,
                        }}
                        style={{
                          display: 'unset',
                        }} /* Inner html should not inherit any display settings */
                      />
                    </ListItem>
                  ) : (
                    <>
                      <a
                        href={sense_url}
                        className={css.linkText}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <>{title}</>
                      </a>
                      <div>{location}</div>
                    </>
                  )}
                </div>
              );
            })}
          </List>
        )}
      </div>

      {showMatchDetails && (
        <Jobs
          isLast={isLast}
          matches={accessory.payload}
          onSubmit={onSubmit}
          setShowMatchDetails={setShowMatchDetails}
          isQuestionNode={isQuestionNode}
          showJobDetails={showJobDetails}
          setShowJobDetails={setShowJobDetails}
          selectedMatch={selectedMatch}
        />
      )}
    </>
  );
}

function MultiSelectAccessory({
  accessory,
  buttonStyle,
  onSubmit,
}: {
  accessory: MultiSelectMessage;
  buttonStyle: ButtonStyle;
  onSubmit: HandlePostMessage;
}) {
  const {jsonValue, setJsonValue, setValue, value} = React.useContext(
    TextInputContext,
  );

  const selectedValues = new Set<string>(jsonValue.filter(Boolean));

  const handleSubmit = () => {
    postMultiSelectMessage(accessory, jsonValue, onSubmit, v =>
      Array.isArray(v) ? setJsonValue(v) : setValue(v),
    );
  };

  return (
    <div className={css.multiSelectRoot}>
      <ButtonGroup
        buttons={accessory.buttons}
        buttonStyle={buttonStyle}
        onPostMessage={(text, payload) => {
          if (selectedValues.has(payload)) {
            selectedValues.delete(payload);
          } else {
            selectedValues.add(payload);
          }
          setJsonValue(Array.from(selectedValues));
        }}
        selectedValues={selectedValues}
      />
      <Button
        type="primary"
        style={buttonStyle}
        onClick={handleSubmit}
        disabled={selectedValues.size === 0}
      >
        Submit
      </Button>
    </div>
  );
}

function ButtonAccessory({
  accessory,
  onSubmit,
  buttonStyle,
}: {
  accessory: SummaryStatusMessage | SingleSelectMessage;
  onSubmit: HandlePostMessage;
  buttonStyle: ButtonStyle;
}) {
  return (
    <div className={css.multiSelectRoot}>
      <ButtonGroup
        buttons={accessory.buttons}
        buttonStyle={buttonStyle}
        onPostMessage={(text, payload) => {
          onSubmit(text, [payload]);
        }}
      />
    </div>
  );
}

function DatePickerAccessory({
  accessory,
  onSubmit,
}: {
  accessory: DatePickerMessage;
  onSubmit: HandlePostMessage;
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleSubmit = () => {
    const value = inputRef.current?.value;
    if (value) {
      onSubmit(value, value);
    }
  };

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <p>{accessory.placeholder?.text}</p>
      <input type="date" ref={inputRef} />
      <Button onClick={handleSubmit}>Submit</Button>
    </form>
  );
}

function FileUploadAccessory({
  accessory,
  onSubmit,
  buttonStyle,
}: {
  accessory: FileUploadMessage;
  onSubmit: HandlePostMessage;
  buttonStyle: ButtonStyle;
}) {
  const {dispatch} = useChatbotState();
  const {setValue} = React.useContext(TextInputContext);

  return (
    <div>
      <label className={css.fileUploadButton}>
        <Button type="primary" style={buttonStyle}>
          Choose File to Upload
        </Button>
        <input
          type="file"
          className={css.fileUploadInput}
          accept="image/*,.pdf,.doc,.docx"
          onChange={e => {
            if (e.target.files?.length) {
              // @ts-ignore
              dispatch({type: 'update_file', payload: e.target.files[0]});
              setValue(e.target.files[0].name);
            }
          }}
        />
      </label>
    </div>
  );
}

type SelectOption = {value: string; label: string};

function SelectAccessory({
  text,
  onSubmit,
  options,
}: {
  text: TextData;
  onSubmit: HandlePostMessage;
  options: Array<SelectOption>;
}) {
  const inputRef = React.useRef<HTMLSelectElement>(null);
  const handleSubmit = () => {
    const value = inputRef.current?.value;
    if (value) {
      onSubmit(value, value);
    }
  };

  return (
    <form>
      <select ref={inputRef}>
        {options.map(({label, value}) => (
          <option value={value}>{label}</option>
        ))}
      </select>
      <Button onClick={handleSubmit}>Submit</Button>
    </form>
  );
}

function DataSelectAccessory({
  dataSource,
  ...props
}: {
  text: TextData;
  onSubmit: HandlePostMessage;
  dataSource: string;
}) {
  const options = useTransformedStateApi<string, SelectOption[]>(
    dataSource,
    {
      transform: response => {
        return response.split('\n').map(value => ({
          value,
          label: value,
        }));
      },
    },
    [],
  );

  if (!options) {
    return null;
  }

  return <SelectAccessory {...props} options={options} />;
}

const feedbackValues = [
  '1: Excellent ',
  '2: Great',
  '3: Ok',
  '4: Not so good',
  '5: Awful',
  '6: No feedback',
];
const feedbackOptions = feedbackValues.map(value => ({
  value: value,
  label: value,
}));

const accessoryStub: DatePickerAccessoryData = {
  type: 'datepicker',
  placeholder: {type: 'plain_text', text: 'What date?'},
  initial_date: '',
};

const SchedulerAccessory = ({
  templateId,
  onSubmit,
  handleScrollToMessage,
}: {
  templateId?: string;
  onSubmit: HandlePostMessage;
  handleScrollToMessage: () => any;
}) => {
  // const availability = useStubApi<APIAvailabilityData>(stubAvailabilityData);
  const {state} = useChatbotState();
  const viewer_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const availability = useStateResourceApi<APIAvailabilityData>(
    {
      pathname: `/scheduler/event-template/${templateId}/availability`,
      query: {
        viewer_timezone: viewer_timezone,
        source_id: state?.sessionId,
      },
    },
    {api: senseApi},
    [],
  );

  React.useLayoutEffect(() => {
    handleScrollToMessage();
  }, [availability.result, handleScrollToMessage]);

  if (availability?.isLoading) {
    return <TypingAnimation />;
  }

  return (
    <FullWeekPicker
      availability={availability.result.free_time_slots}
      onSelect={(selectedDateTime: Date) => {
        // TODO(marcos): this is the future format, pending artifacts
        onSubmit('', `${formatISO(selectedDateTime)} ${viewer_timezone}`);
        //onSubmit('', formatISO(selectedDateTime));
      }}
    />
  );
};

type APIAvailabilityData = {
  free_time_slots: {[key: string]: {start_time: string; end_time: string}[]};
};

export const messageTypesWithAccessory = [
  'feedback',
  'multi-select',
  'single-select',
  'scheduler',
  'summary',
  'job_match',
  'file_upload',
  'lat-wait-start',
];
