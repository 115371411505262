import React from 'react';
import {ChatId, BrandingSettings, InboundDisplayMode, FlowData} from 'types';
import {ApiError} from 'utils/api';
import BaseView from 'components/BaseView/BaseView';
import {ErrorPage, NotFound} from 'components/Loading/Loading';
import {ResponsiveProvider} from 'context/Responsive';
import appCss from './App.module.css';

const App = ({
  chatId,
  isSms,
  isPreview = false,
  handleLogoClick,
  flowBrandSettings,
  handleChangeDisplayMode,
  inboundDisplayMode,
  isMobile,
  side = 'right',
  error,
  loadTranscript = false,
  customParams,
  qnOverSms = false,
  jobSelected = false,
  agencyId = '',
  flowData,
}: {
  chatId: ChatId;
  isSms: boolean;
  isPreview?: boolean;
  handleLogoClick?: () => void;
  handleChangeDisplayMode?: (displayMode: InboundDisplayMode) => void;
  inboundDisplayMode?: InboundDisplayMode;
  flowBrandSettings?: BrandingSettings;
  isMobile?: boolean;
  side?: 'left' | 'right';
  error?: ApiError | string;
  loadTranscript?: boolean;
  customParams?: {[key: string]: string};
  qnOverSms?: boolean;
  jobSelected?: boolean;
  flowData?: FlowData;
  agencyId?: string;
}) => {
  if (error) {
    return (
      <div className={appCss.body}>
        <ErrorPage error={error} />
      </div>
    );
  }

  const maximizeInboundDisplay = () => {
    if (handleChangeDisplayMode && inboundDisplayMode !== 'max') {
      handleChangeDisplayMode('max');
    }
  };

  return (
    <ResponsiveProvider>
      <div className={appCss.body}>
        {chatId.value ? (
          <BaseView
            chatId={chatId}
            isSms={isSms}
            isPreview={isPreview}
            handleLogoClick={handleLogoClick}
            flowBrandSettings={flowBrandSettings}
            handleChangeDisplayMode={handleChangeDisplayMode}
            inboundDisplayMode={inboundDisplayMode}
            maximizeInboundDisplay={maximizeInboundDisplay}
            isMobile={isMobile}
            side={side}
            customParams={customParams}
            loadTranscript={loadTranscript}
            qnOverSms={qnOverSms}
            jobSelected={jobSelected}
            flowData={flowData}
            agencyId={agencyId}
          />
        ) : (
          <NotFound />
        )}
      </div>
    </ResponsiveProvider>
  );
};

export default App;
